import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Section, Container } from "../components/utils"
import Hero from "../components/servicehero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const ServiceSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const Service = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 100%;
    @media (min-width: 800px) {
      flex: 1 1 20%;
    }
  }
  padding: 4rem 2rem;
  &:nth-of-type(2n + 1):nth-of-type(4n + 1),
  &:nth-of-type(2n + 4):nth-of-type(4n + 4) {
    background: ${({ theme }) => theme.paleBlue};
  }
  @media (max-width: 800px) {
    &:nth-of-type(n + 1) {
      background: white !important;
    }
    &:nth-of-type(2n + 1) {
      background: ${({ theme }) => theme.paleBlue} !important;
    }
  }
  h2 {
    margin-bottom: 1.5rem;
  }
  p {
    /* margin-bottom: 0; */
    font-weight: 300;
    grid-column: 1;
  }
  .button {
    text-align: center;
    grid-column: 2;
    font-size: 0.6rem;
  }
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`

const ServicesPage = ({ data }) => {
  const { title, heroImage, intro, services } = data.page
  return (
    <Layout>
      <Hero image={heroImage}>
        <div>
          <h1>{title}</h1>
          <p>{intro}</p>
        </div>
      </Hero>
      <ServiceSection>
        {services.map(({ title, slug, shortDescription, icon }) => (
          <Service key={slug}>
            <div>
              <h2>{title}</h2>
              <p>{shortDescription}</p>
            </div>
            <div className="icon">
              {icon && <Img fixed={icon.fixed} className="mb-1" />}
              <Link className="button button-outline" to={`/services/${slug}`}>
                Find out more
              </Link>
            </div>
          </Service>
        ))}
        <Service>
          <Container>
            <h2>Need something bespoke?</h2>
            <p className="mb-2">
              We're here to help, so if you've got a problem we haven't covered
              here, feel free to get in touch and we'll see what we can do.
            </p>
            <Link className="button button-outline mt-2" to={`/contact`}>
              Contact Us
            </Link>
          </Container>
        </Service>
      </ServiceSection>
      <SEO title="Services" />
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  {
    page: datoCmsServicesPage {
      title
      heroImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      intro
      services {
        title
        slug
        shortDescription
        icon {
          fixed(width: 120) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
    }
  }
`
